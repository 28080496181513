define("labs-applicant-maps/mirage/factories/geometric-property", ["exports", "ember-cli-mirage", "labs-applicant-maps/mirage/helpers/random-geometry"], function (_exports, _emberCliMirage, _randomGeometry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var randomPolygon = _randomGeometry.default.randomPolygon;
  var annotations = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      properties: {
        'meta:mode': 'draw_annotations:linear',
        label: '29 ft'
      },
      geometry: {
        coordinates: [[-73.91311260409391, 40.75817100752687], [-73.91314440749528, 40.75808962172928]],
        type: 'LineString'
      }
    }]
  };

  var _default = _emberCliMirage.Factory.extend({
    proposedGeometry: function proposedGeometry() {
      return randomPolygon(1, {
        // Manhattan bbox
        bbox: [-73.972866, 40.767488, -73.996735, 40.745782],
        num_vertices: _emberCliMirage.faker.random.arrayElement([4, 6, 8, 10]),
        max_radial_length: 0.003
      });
    },
    hasAnnotations: (0, _emberCliMirage.trait)({
      annotations: annotations
    })
  });

  _exports.default = _default;
});