define("labs-applicant-maps/mirage/helpers/handle-fake-carto-geometries", ["exports", "@turf/bbox", "@turf/voronoi", "@turf/helpers", "@turf/transform-scale", "ember-cli-mirage", "ember-cli-mirage/response", "labs-applicant-maps/mirage/helpers/random-geometry"], function (_exports, _bbox, _voronoi, _helpers, _transformScale, _emberCliMirage, _response, _randomGeometry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  // eslint-disable-line
  var randomPoint = _randomGeometry.default.randomPoint,
      randomPolygon = _randomGeometry.default.randomPolygon;
  var feature = _helpers.default.feature;

  function _default(schema, request) {
    var _request$queryParams = request.queryParams;
    _request$queryParams = _request$queryParams === void 0 ? {} : _request$queryParams;
    var q = _request$queryParams.q,
        format = _request$queryParams.format;
    var JSONAble = (q.match(/\{(.*)\}/) || [])[0]; // can't parse the geoJSON, but it's requesting geoJSON

    if (!JSONAble && format === 'geojson') return randomPolygon(5); // it's requesting something other than GeoJSON

    if (format !== 'geojson') return {
      rows: []
    }; // it's JSONAble so we can compute some stuff from it

    if (JSONAble) {
      var jsonObject = JSON.parse(JSONAble);
      var queryFeatureCollection = {
        type: 'FeatureCollection',
        features: [feature(jsonObject)]
      };
      var bbox = (0, _bbox.default)((0, _transformScale.default)(queryFeatureCollection, 3));
      var randomPoints = randomPoint(3, {
        bbox: bbox
      });
      var randomZoning = randomPolygon(1, {
        // Manhattan bbox
        bbox: [-73.972866, 40.767488, -73.996735, 40.745782],
        max_radial_length: 0.005,
        num_vertices: 4
      });
      var fakeZoningDistricts = (0, _voronoi.default)(randomPoints, {
        bbox: bbox
      });

      if (q.match('planninglabs.zoning_districts')) {
        if (schema.stableGeometries.zoning_districts) {
          return schema.stableGeometries.zoning_districts;
        }

        schema.stableGeometries.zoning_districts = fakeZoningDistricts;
        schema.stableGeometries.zoning_districts.features.forEach(function (zoningDistrict) {
          zoningDistrict.properties = {
            id: _emberCliMirage.faker.random.uuid(),
            label: _emberCliMirage.faker.random.word()
          };
        });
        return schema.stableGeometries.zoning_districts;
      }

      if (q.match('planninglabs.special_purpose_districts')) {
        if (schema.stableGeometries.special_purpose_districts) {
          return schema.stableGeometries.special_purpose_districts;
        }

        schema.stableGeometries.special_purpose_districts = randomZoning;
        schema.stableGeometries.special_purpose_districts.features.forEach(function (specialPurposeDistrict) {
          specialPurposeDistrict.properties = {
            id: _emberCliMirage.faker.random.uuid(),
            label: _emberCliMirage.faker.random.word()
          };
        });
        return schema.stableGeometries.special_purpose_districts;
      }

      if (q.match('planninglabs.commercial_overlays')) {
        if (schema.stableGeometries.commercial_overlays) {
          return schema.stableGeometries.commercial_overlays;
        }

        schema.stableGeometries.commercial_overlays = randomZoning;
        schema.stableGeometries.commercial_overlays.features.forEach(function (commercialOverlay) {
          commercialOverlay.properties = {
            id: _emberCliMirage.faker.random.uuid(),
            label: _emberCliMirage.faker.random.word()
          };
        });
        return schema.stableGeometries.commercial_overlays;
      }
    }

    return new _response.default(400);
  }
});