define("labs-applicant-maps/mirage/factories/zoning-section-map", ["exports", "labs-applicant-maps/mirage/factories/applicant-map"], function (_exports, _applicantMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicantMap.default.extend({});

  _exports.default = _default;
});