define("labs-applicant-maps/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/applicant-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/applicant-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/area-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/area-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/geometric-property.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/geometric-property.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/layer-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/layer-group.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/project.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/tax-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/tax-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/zoning-change-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/zoning-change-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/zoning-section-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/zoning-section-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/geometric-properties.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/geometric-properties.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/projects.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/projects.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/helpers/handle-fake-carto-geometries.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/handle-fake-carto-geometries.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/helpers/mirage-mapbox-gl-monkeypatch.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/mirage-mapbox-gl-monkeypatch.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/helpers/random-geometry.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/random-geometry.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/helpers/static-styles.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/static-styles.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/applicant-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/applicant-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/area-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/area-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/geometric-property.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/geometric-property.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/project.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/tax-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/tax-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/zoning-change-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/zoning-change-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/zoning-section-map.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/zoning-section-map.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});