define("labs-applicant-maps/mirage/helpers/random-geometry", ["exports", "@turf/random"], function (_exports, _random) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { helper } from '@ember/component/helper';
  var randomPoint = _random.default.randomPoint;

  var randomPolygon = function randomPolygon(count) {
    if (count === 1) {
      return {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          properties: {
            id: 9999
          },
          geometry: {
            type: 'Polygon',
            coordinates: [[[-73.91304371809123, 40.75800497849508], [-73.91272236764055, 40.75785653226183], [-73.91253338845002, 40.75808671409273], [-73.91285462634531, 40.75823546499302], [-73.91295060921382, 40.758120288754554], [-73.91304371809123, 40.75800497849508]]]
          }
        }]
      };
    }

    if (count === 2) {
      return {
        type: 'FeatureCollection',
        features: [{
          id: 5371,
          type: 'Feature',
          properties: {
            label: 'C2-1',
            id: 5371
          },
          geometry: {
            coordinates: [[[-74.127648, 40.612206], [-74.127657, 40.612206], [-74.127661, 40.612235], [-74.127711, 40.612615], [-74.127296, 40.612652], [-74.127196, 40.612661], [-74.127088, 40.612671], [-74.126943, 40.612684], [-74.126904, 40.612408], [-74.126887, 40.612286], [-74.126886, 40.612275], [-74.127648, 40.612206]]],
            type: 'Polygon'
          }
        }, {
          id: 5398,
          type: 'Feature',
          properties: {
            label: 'C2-1',
            id: 5398
          },
          geometry: {
            coordinates: [[[-74.125933, 40.61294], [-74.126097, 40.612926], [-74.126272, 40.612912], [-74.12634, 40.612906], [-74.126417, 40.612899], [-74.126483, 40.612894], [-74.126565, 40.612887], [-74.126632, 40.612881], [-74.126844, 40.612863], [-74.12699, 40.612851], [-74.127032, 40.613163], [-74.127032, 40.613163], [-74.125969, 40.613234], [-74.125933, 40.61294]]],
            type: 'Polygon'
          }
        }]
      };
    }

    return {
      type: 'FeatureCollection',
      features: [{
        id: 5371,
        type: 'Feature',
        properties: {
          label: 'C2-1',
          id: 5371
        },
        geometry: {
          coordinates: [[[-74.127648, 40.612206], [-74.127657, 40.612206], [-74.127661, 40.612235], [-74.127711, 40.612615], [-74.127296, 40.612652], [-74.127196, 40.612661], [-74.127088, 40.612671], [-74.126943, 40.612684], [-74.126904, 40.612408], [-74.126887, 40.612286], [-74.126886, 40.612275], [-74.127648, 40.612206]]],
          type: 'Polygon'
        }
      }, {
        id: 5398,
        type: 'Feature',
        properties: {
          label: 'C2-1',
          id: 5398
        },
        geometry: {
          coordinates: [[[-74.125933, 40.61294], [-74.126097, 40.612926], [-74.126272, 40.612912], [-74.12634, 40.612906], [-74.126417, 40.612899], [-74.126483, 40.612894], [-74.126565, 40.612887], [-74.126632, 40.612881], [-74.126844, 40.612863], [-74.12699, 40.612851], [-74.127032, 40.613163], [-74.127032, 40.613163], [-74.125969, 40.613234], [-74.125933, 40.61294]]],
          type: 'Polygon'
        }
      }, {
        id: 5411,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5411
        },
        geometry: {
          coordinates: [[[-74.130267, 40.61197], [-74.130262, 40.611913], [-74.130368, 40.61192], [-74.130434, 40.611924], [-74.131118, 40.611874], [-74.131136, 40.611873], [-74.13114, 40.611909], [-74.131148, 40.611999], [-74.131155, 40.612079], [-74.13116, 40.612133], [-74.131187, 40.612421], [-74.130976, 40.612436], [-74.130611, 40.612462], [-74.13044, 40.612475], [-74.130312, 40.612467], [-74.130283, 40.61214], [-74.130275, 40.612053], [-74.130267, 40.61197]]],
          type: 'Polygon'
        }
      }, {
        id: 5474,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5474
        },
        geometry: {
          coordinates: [[[-74.129002, 40.6121], [-74.12902, 40.612099], [-74.129059, 40.61251], [-74.128725, 40.612537], [-74.128436, 40.61256], [-74.128401, 40.612285], [-74.128387, 40.612176], [-74.128384, 40.61215], [-74.129002, 40.6121]]],
          type: 'Polygon'
        }
      }, {
        id: 5498,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5498
        },
        geometry: {
          coordinates: [[[-74.131206, 40.616415], [-74.131172, 40.616129], [-74.13176, 40.616223], [-74.131688, 40.616492], [-74.131206, 40.616415]]],
          type: 'Polygon'
        }
      }, {
        id: 5537,
        type: 'Feature',
        properties: {
          label: 'C2-1',
          id: 5537
        },
        geometry: {
          coordinates: [[[-74.128255, 40.612161], [-74.128261, 40.61216], [-74.128265, 40.612191], [-74.128277, 40.61229], [-74.128279, 40.612307], [-74.128282, 40.612328], [-74.128313, 40.61257], [-74.12821, 40.612578], [-74.128152, 40.612583], [-74.128094, 40.612588], [-74.128018, 40.612594], [-74.127884, 40.612605], [-74.127848, 40.612324], [-74.127835, 40.612226], [-74.127831, 40.612195], [-74.128255, 40.612161]]],
          type: 'Polygon'
        }
      }, {
        id: 5547,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5547
        },
        geometry: {
          coordinates: [[[-74.136766, 40.611624], [-74.136786, 40.611405], [-74.137157, 40.611425], [-74.137487, 40.611442], [-74.137503, 40.611456], [-74.137522, 40.611467], [-74.137543, 40.611476], [-74.137566, 40.611482], [-74.13759, 40.611484], [-74.137614, 40.611484], [-74.137637, 40.611481], [-74.13766, 40.611474], [-74.137681, 40.611465], [-74.137699, 40.611454], [-74.13792, 40.611465], [-74.138011, 40.611713], [-74.138024, 40.611829], [-74.137964, 40.61184], [-74.137858, 40.61186], [-74.137801, 40.61187], [-74.137738, 40.611882], [-74.137679, 40.611893], [-74.137573, 40.611913], [-74.137403, 40.611944], [-74.136727, 40.612071], [-74.136755, 40.611748], [-74.136761, 40.611686], [-74.136766, 40.611624]]],
          type: 'Polygon'
        }
      }, {
        id: 5585,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5585
        },
        geometry: {
          coordinates: [[[-74.129245, 40.612498], [-74.129217, 40.612209], [-74.129209, 40.612129], [-74.129205, 40.612088], [-74.129979, 40.612034], [-74.130073, 40.61204], [-74.130074, 40.612044], [-74.130083, 40.612149], [-74.130111, 40.612455], [-74.129982, 40.612447], [-74.129581, 40.612475], [-74.1294, 40.612488], [-74.129245, 40.612498]]],
          type: 'Polygon'
        }
      }, {
        id: 5593,
        type: 'Feature',
        properties: {
          label: 'C2-1',
          id: 5593
        },
        geometry: {
          coordinates: [[[-74.132761, 40.612486], [-74.13312, 40.612466], [-74.133119, 40.612645], [-74.133116, 40.612879], [-74.132366, 40.612921], [-74.132396, 40.612861], [-74.132407, 40.612506], [-74.132761, 40.612486]]],
          type: 'Polygon'
        }
      }, {
        id: 5631,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5631
        },
        geometry: {
          coordinates: [[[-74.132414, 40.611778], [-74.133134, 40.611742], [-74.133133, 40.611894], [-74.133132, 40.612003], [-74.133129, 40.612292], [-74.132775, 40.61231], [-74.132413, 40.612328], [-74.132413, 40.612041], [-74.132413, 40.611988], [-74.132413, 40.611941], [-74.132414, 40.611893], [-74.132414, 40.611844], [-74.132414, 40.611778]]],
          type: 'Polygon'
        }
      }, {
        id: 5665,
        type: 'Feature',
        properties: {
          label: 'C1-2',
          id: 5665
        },
        geometry: {
          coordinates: [[[-74.134101, 40.615198], [-74.134585, 40.614947], [-74.134754, 40.614979], [-74.134686, 40.615202], [-74.134605, 40.615463], [-74.134573, 40.615459], [-74.134575, 40.615443], [-74.134397, 40.615419], [-74.134398, 40.615402], [-74.134376, 40.615395], [-74.134352, 40.615387], [-74.134325, 40.615376], [-74.134298, 40.615363], [-74.134269, 40.615348], [-74.134245, 40.615334], [-74.134216, 40.615315], [-74.134205, 40.615306], [-74.134187, 40.615292], [-74.134172, 40.61528], [-74.134162, 40.615271], [-74.134151, 40.615258], [-74.134143, 40.615248], [-74.134119, 40.615219], [-74.134101, 40.615198]]],
          type: 'Polygon'
        }
      }, {
        id: 5674,
        type: 'Feature',
        properties: {
          label: 'C2-1',
          id: 5674
        },
        geometry: {
          coordinates: [[[-74.130842, 40.613164], [-74.130808, 40.612858], [-74.131162, 40.612967], [-74.131422, 40.613047], [-74.131441, 40.613053], [-74.131553, 40.613087], [-74.13159, 40.613395], [-74.130842, 40.613164]]],
          type: 'Polygon'
        }
      }, {
        id: 5748,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5748
        },
        geometry: {
          coordinates: [[[-74.132005, 40.612536], [-74.132196, 40.612519], [-74.132192, 40.612793], [-74.132192, 40.612833], [-74.132045, 40.613124], [-74.131979, 40.613093], [-74.131924, 40.613069], [-74.131873, 40.613048], [-74.131819, 40.613031], [-74.131756, 40.613012], [-74.131644, 40.612979], [-74.131459, 40.612923], [-74.131101, 40.612817], [-74.131101, 40.612719], [-74.131102, 40.612615], [-74.131462, 40.612584], [-74.131649, 40.612567], [-74.131824, 40.612552], [-74.132005, 40.612536]]],
          type: 'Polygon'
        }
      }, {
        id: 5759,
        type: 'Feature',
        properties: {
          label: 'C2-1',
          id: 5759
        },
        geometry: {
          coordinates: [[[-74.126626, 40.612297], [-74.126716, 40.612289], [-74.12672, 40.612315], [-74.126735, 40.612424], [-74.126773, 40.612699], [-74.126624, 40.612712], [-74.126513, 40.612721], [-74.126402, 40.61273], [-74.126294, 40.612739], [-74.126295, 40.612745], [-74.12602, 40.612768], [-74.125912, 40.612777], [-74.125912, 40.612771], [-74.125913, 40.612533], [-74.125938, 40.612354], [-74.126626, 40.612297]]],
          type: 'Polygon'
        }
      }, {
        id: 5769,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5769
        },
        geometry: {
          coordinates: [[[-74.132214, 40.611814], [-74.132261, 40.611804], [-74.132262, 40.611883], [-74.132262, 40.611984], [-74.132264, 40.612362], [-74.131907, 40.612381], [-74.131615, 40.612397], [-74.13139, 40.612409], [-74.131359, 40.612054], [-74.131353, 40.61198], [-74.131345, 40.611896], [-74.131342, 40.611861], [-74.132214, 40.611814]]],
          type: 'Polygon'
        }
      }, {
        id: 5815,
        type: 'Feature',
        properties: {
          label: 'C1-1',
          id: 5815
        },
        geometry: {
          coordinates: [[[-74.130316, 40.613214], [-74.130297, 40.612945], [-74.127295, 40.613146], [-74.127295, 40.613145], [-74.127251, 40.612822], [-74.127415, 40.612808], [-74.127469, 40.612804], [-74.127542, 40.612798], [-74.127617, 40.612791], [-74.128327, 40.612732], [-74.128332, 40.61278], [-74.129019, 40.612733], [-74.129112, 40.612726], [-74.129205, 40.61272], [-74.129334, 40.612711], [-74.129423, 40.612705], [-74.129512, 40.612699], [-74.129693, 40.612687], [-74.130133, 40.612656], [-74.130223, 40.61265], [-74.130254, 40.612648], [-74.130312, 40.612661], [-74.130611, 40.612729], [-74.130629, 40.612914], [-74.130643, 40.613048], [-74.130657, 40.61319], [-74.130316, 40.613214]]],
          type: 'Polygon'
        }
      }]
    };
  };

  var _default = {
    randomPoint: randomPoint,
    randomPolygon: randomPolygon
  };
  _exports.default = _default;
});