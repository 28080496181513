define("labs-applicant-maps/mirage/factories/applicant-map", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    paperOrientation: function paperOrientation() {
      return _emberCliMirage.faker.random.arrayElement(['landscape', 'portrait']);
    },
    paperSize: function paperSize() {
      return _emberCliMirage.faker.random.arrayElement(['tabloid', 'letter']);
    },
    mapBearing: function mapBearing() {
      return _emberCliMirage.faker.random.number(-180, 180);
    },
    boundsPolygon: function boundsPolygon() {
      return {
        type: 'Polygon',
        coordinates: [[[-73.99976877714354, 40.720674259759846], [-73.99479354511911, 40.720674259759846], [-73.99479354511911, 40.717534531206525], [-73.99976877714354, 40.717534531206525], [-73.99976877714354, 40.720674259759846]]],
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326'
          }
        }
      };
    }
  });

  _exports.default = _default;
});