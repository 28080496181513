define("labs-applicant-maps/templates/projects/edit/steps/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+hlO1x+X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nav\"],[11,\"class\",\"step-nav\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"projects.edit.steps.rezoning\"],[[\"tagName\",\"class\"],[\"span\",\"button small gray small-margin-bottom\"]],{\"statements\":[[0,\"    \"],[1,[27,\"fa-icon\",[\"arrow-left\"],null],false],[0,\" Back\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"h1\"],[9],[0,\"You've finished defining your changes.\"],[10],[0,\"\\n\\n\"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"Next, go to your project dashboard, where you can:\"],[10],[0,\"\\n\"],[7,\"ul\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"Review your proposed changes\"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"Make changes or additions to geometries you just created\"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"Print and download your project maps\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"p\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"data-test-go-to-dash\",\"\"],[11,\"class\",\"button large\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"goToProjectDashboard\"],null]],[9],[0,\"\\n    Go to Project Dashboard \"],[1,[27,\"fa-icon\",[\"arrow-right\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/projects/edit/steps/complete.hbs"
    }
  });

  _exports.default = _default;
});