define("labs-applicant-maps/templates/projects/edit/steps/development-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XaEWw+SN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[9],[0,\"First, let’s define your Development Site.\"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\"The Development Site refers to the zoning lot(s) you plan to develop, facilitated by your requested land use actions.\"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[11,\"class\",\"header-medium\"],[9],[0,\"To create a Development Site, you can either:\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"grid-x grid-margin-x\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"cell medium-6 large-5 xlarge-4\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"projects.edit.geometry-edit\",[23,[\"model\",\"id\"]],[27,\"query-params\",null,[[\"mode\",\"type\"],[\"lots\",\"development-site\"]]]],[[\"data-test-select-lots\",\"tagName\",\"class\"],[true,\"span\",\"button large expanded\"]],{\"statements\":[[0,\"      \"],[1,[27,\"fa-icon\",[\"hand-pointer\"],[[\"fixedWidth\",\"transform\"],[true,\"left-3 grow-3\"]]],false],[0,\"\\n      Select Lots\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-small text-center\"],[9],[0,\"Create a Development Site that is coterminous with chosen lots.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"cell medium-6 large-5 xlarge-4\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"projects.edit.geometry-edit\",[23,[\"model\",\"id\"]],[27,\"query-params\",null,[[\"mode\",\"type\"],[\"draw\",\"development-site\"]]]],[[\"data-test-draw\",\"tagName\",\"class\"],[true,\"span\",\"button large expanded\"]],{\"statements\":[[0,\"      \"],[1,[27,\"fa-icon\",[\"draw-polygon\"],[[\"fixedWidth\",\"transform\"],[true,\"left-3 grow-3\"]]],false],[0,\"\\n      Draw Manually\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-small text-center\"],[9],[0,\"Use draw tools to create a Development Site that includes partial lots\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/projects/edit/steps/development-site.hbs"
    }
  });

  _exports.default = _default;
});