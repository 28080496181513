define("labs-applicant-maps/mirage/fixtures/geometric-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    geometryType: 'developmentSite',
    proposedGeometry: {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {},
        id: 1,
        geometry: {
          type: 'Polygon',
          coordinates: [[[-73.91304371809123, 40.75800497849508], [-73.91272236764055, 40.75785653226183], [-73.91253338845002, 40.75808671409273], [-73.91285462634531, 40.75823546499302], [-73.91295060921382, 40.758120288754554], [-73.91304371809123, 40.75800497849508]]]
        }
      }]
    }
  }, {
    id: 2,
    geometryType: 'projectArea',
    proposedGeometry: {
      type: 'FeatureCollection',
      features: [{
        id: 2,
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[[-73.91304371809123, 40.75800497849508], [-73.91272236764055, 40.75785653226183], [-73.91253338845002, 40.75808671409273], [-73.91285462634531, 40.75823546499302], [-73.91295060921382, 40.758120288754554], [-73.91304371809123, 40.75800497849508]]]
        }
      }]
    }
  }];
  _exports.default = _default;
});