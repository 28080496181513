define("labs-applicant-maps/mirage/config", ["exports", "labs-applicant-maps/mirage/helpers/mirage-mapbox-gl-monkeypatch", "labs-applicant-maps/mirage/helpers/handle-fake-carto-geometries", "labs-applicant-maps/config/environment", "labs-applicant-maps/mirage/helpers/static-styles"], function (_exports, _mirageMapboxGlMonkeypatch, _handleFakeCartoGeometries, _environment, _staticStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var interceptCarto = _environment.default.interceptCarto;

  function _default() {
    (0, _mirageMapboxGlMonkeypatch.default)();
    this.schema.stableGeometries = {
      zoning_districts: null,
      commercial_overlays: null,
      special_purpose_districts: null
    };

    if (this.environment === 'test' || interceptCarto) {
      // generate geojson from memory for testing
      this.get('https://planninglabs.carto.com/api/v2/sql', _handleFakeCartoGeometries.default);
      this.post('/layer-groups', function (schema) {
        var response = schema.layerGroups.all();
        var json = this.serialize(response);
        json.meta = {
          mapboxStyle: _staticStyles.layersAPIStyle
        };
        return json;
      });
      this.get('/layer-groups/:id');
    } else {
      // intercept and generate geojson from server
      this.passthrough('https://planninglabs.carto.com/**');
    } // map interceptions


    this.passthrough('https://layers-api.planninglabs.nyc/**');
    this.passthrough('https://labs-layers-api-staging.herokuapp.com/**');
    this.passthrough('https://tiles.planninglabs.nyc/**');
    this.passthrough('https://layers-api-staging.planninglabs.nyc/**');
    this.passthrough('https://raw.githubusercontent.com/**');
    this.passthrough('http://raw.githubusercontent.com/**');
    this.passthrough('https://raw.githubusercontent.com/**');
    this.passthrough('/write-coverage');
    this.passthrough('http://localhost:3000/**');
    this.passthrough('https://maputnik-push.planninglabs.nyc/**');
    this.passthrough('https://upload.wikimedia.org/**');
    this.passthrough('/img/**'); // 3rd Party APIs

    this.get('https://search-api-production.herokuapp.com/**', function () {
      return [{
        label: 'test',
        bbl: 100000,
        geometry: {
          type: 'Point',
          coordinates: [0, 0]
        },
        type: 'lot'
      }];
    }); // REST Endpoints

    this.get('/projects');
    this.get('/projects/:id');
    this.patch('/projects/:id');
    this.post('/projects');
    this.patch('/geometric-properties/:id');
    this.post('/geometric-properties');
    this.post('/area-maps');
    this.patch('/area-maps/:id');
    this.post('/tax-maps');
    this.patch('/tax-maps/:id');
    this.post('/zoning-change-maps');
    this.patch('/zoning-change-maps/:id');
    this.post('/zoning-section-maps');
    this.patch('/zoning-section-maps/:id');
    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       http://www.ember-cli-mirage.com/docs/v0.3.x/shorthands/
    */
  }
});