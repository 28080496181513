define("labs-applicant-maps/mirage/factories/project", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    hasDevelopmentSite: (0, _emberCliMirage.trait)({
      needDevelopmentSite: true,
      afterCreate: function afterCreate(project, server) {
        server.create('geometric-property', {
          project: project,
          geometryType: 'developmentSite'
        });
      }
    }),
    hasProjectArea: (0, _emberCliMirage.trait)({
      needProjectArea: true,
      afterCreate: function afterCreate(project, server) {
        server.create('geometric-property', {
          project: project,
          geometryType: 'projectArea'
        });
      }
    }),
    projectName: function projectName() {
      return 'Mullberry Crossing';
    },
    applicantName: function applicantName() {
      return 'CMW Properties, LLC';
    },
    zapProjectId: function zapProjectId() {
      return _emberCliMirage.faker.random.uuid();
    },
    datePrepared: function datePrepared() {
      return _emberCliMirage.faker.date.past();
    },
    needProjectArea: function needProjectArea() {
      return _emberCliMirage.faker.random.boolean();
    },
    needRezoning: function needRezoning() {
      // return faker.random.boolean();
      return false;
    },
    needUnderlyingZoning: function needUnderlyingZoning() {
      // eslint-disable-line
      if (this.needRezoning) {
        return _emberCliMirage.faker.random.boolean();
      }
    },
    needCommercialOverlay: function needCommercialOverlay() {
      // eslint-disable-line
      if (this.needRezoning) {
        return _emberCliMirage.faker.random.boolean();
      }
    },
    needSpecialDistrict: function needSpecialDistrict() {
      // eslint-disable-line
      if (this.needRezoning) {
        return _emberCliMirage.faker.random.boolean();
      }
    }
  });

  _exports.default = _default;
});