define("labs-applicant-maps/mirage/helpers/mirage-mapbox-gl-monkeypatch", ["exports", "labs-applicant-maps/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var interceptCarto = _environment.default.interceptCarto;

  var _default = function _default() {
    // Note: the below XMLHttpRequest has already been converted to a FakeXMLHttpRequest by pretender
    var origSend = window.XMLHttpRequest.prototype.send;

    window.XMLHttpRequest.prototype.send = function send() {
      origSend.apply(this, arguments); // eslint-disable-line

      var fakeXhr = this; // eslint-disable-line consistent-this

      var realXhr = this._passthroughRequest;

      if (realXhr) {
        realXhr.onload = function (event) {
          if (fakeXhr.responseType !== 'arraybuffer') {
            fakeXhr.response = realXhr.response;
          } // dispatch event instead of calling the original to prevent a double call bug


          fakeXhr.dispatchEvent(event);
        };
      }
    }; // BAD
    // We need this for testing purposes. We need to send back fake array buffers
    // for mapbox-gl to be happy.
    // see https://github.com/pretenderjs/pretender/pull/157


    if (interceptCarto) {
      var origSetResponse = window.FakeXMLHttpRequest.prototype._setResponseBody;

      window.FakeXMLHttpRequest.prototype._setResponseBody = function _setResponseBody(body) {
        this.response = body;

        if (this.responseType === 'arraybuffer') {
          this.response = new ArrayBuffer(1);
        }

        origSetResponse.apply(this, arguments); // eslint-disable-line
      };
    }
  };

  _exports.default = _default;
});