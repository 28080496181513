define("labs-applicant-maps/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    server.loadFixtures('geometric-properties');
    server.loadFixtures('projects');
    server.createList('layer-group', 3);
  }
});