define("labs-applicant-maps/mirage/helpers/static-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.spritesJson = _exports.baseStyle = _exports.v3JSON = _exports.layersAPIStyle = void 0;
  var layersAPIStyle = {
    bearing: 0,
    center: [-73.869324, 40.815888],
    layers: [{
      id: 'highway_path',
      type: 'background'
    }, {
      id: 'highway_minor',
      type: 'background'
    }, {
      id: 'highway_major_casing',
      type: 'background'
    }, {
      id: 'highway_major_inner',
      type: 'background'
    }, {
      id: 'highway_major_subtle',
      type: 'background'
    }, {
      id: 'highway_motorway_casing',
      type: 'background'
    }, {
      id: 'highway_motorway_inner',
      type: 'background'
    }, {
      id: 'highway_motorway_subtle',
      type: 'background'
    }, {
      id: 'highway_motorway_bridge_casing',
      type: 'background'
    }, {
      id: 'highway_motorway_bridge_inner',
      type: 'background'
    }, {
      id: 'highway_name_other',
      type: 'background'
    }, {
      id: 'highway_name_motorway',
      type: 'background'
    }, {
      id: 'tunnel_motorway_casing',
      type: 'background'
    }, {
      id: 'tunnel_motorway_inner',
      type: 'background'
    }, {
      id: 'railway_transit',
      type: 'background'
    }, {
      id: 'railway_transit_dashline',
      type: 'background'
    }, {
      id: 'railway_service',
      type: 'background'
    }, {
      id: 'railway_service_dashline',
      type: 'background'
    }, {
      id: 'railway',
      type: 'background'
    }, {
      id: 'railway_dashline',
      type: 'background'
    }, {
      id: 'boundary_country',
      type: 'background'
    }],
    name: 'NYCPlanning Positron',
    glyphs: 'https://tiles.planninglabs.nyc/fonts/{fontstack}/{range}.pbf',
    pitch: 0,
    sources: {},
    version: 8,
    zoom: 9.72
  };
  _exports.layersAPIStyle = layersAPIStyle;
  var v3JSON = {
    tiles: ['https://tiles.planninglabs.nyc/data/v3/{z}/{x}/{y}.pbf'],
    name: 'OpenMapTiles',
    format: 'pbf',
    basename: 'north-america_us-northeast.mbtiles',
    id: 'openmaptiles',
    attribution: '<a href="http://www.openmaptiles.org/" target="_blank">&copy; OpenMapTiles</a> <a href="http://www.openstreetmap.org/about/" target="_blank">&copy; OpenStreetMap contributors</a>',
    center: [-73.70104, 43.128005, 14],
    description: 'Extract from https://openmaptiles.org',
    maxzoom: 14,
    minzoom: 0,
    pixel_scale: '256',
    version: '3.6.1',
    maskLevel: '5',
    bounds: [-80.52632, 38.77178, -66.87576, 47.48423],
    planettime: '1499040000000',
    vector_layers: [{
      maxzoom: 14,
      fields: {
        class: 'String'
      },
      minzoom: 0,
      id: 'water',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        'name:mt': 'String',
        'name:pt': 'String',
        'name:az': 'String',
        'name:ka': 'String',
        'name:rm': 'String',
        'name:ko': 'String',
        'name:kn': 'String',
        'name:ar': 'String',
        'name:cs': 'String',
        name_de: 'String',
        'name:ro': 'String',
        'name:it': 'String',
        name_int: 'String',
        'name:ru': 'String',
        'name:pl': 'String',
        'name:ca': 'String',
        'name:lv': 'String',
        'name:bg': 'String',
        'name:cy': 'String',
        'name:fi': 'String',
        'name:he': 'String',
        'name:da': 'String',
        'name:de': 'String',
        'name:tr': 'String',
        'name:fr': 'String',
        'name:mk': 'String',
        'name:nonlatin': 'String',
        'name:fy': 'String',
        'name:be': 'String',
        'name:zh': 'String',
        'name:sr': 'String',
        'name:sl': 'String',
        'name:nl': 'String',
        'name:ja': 'String',
        'name:lt': 'String',
        'name:no': 'String',
        'name:kk': 'String',
        'name:ko_rm': 'String',
        'name:ja_rm': 'String',
        'name:br': 'String',
        'name:bs': 'String',
        'name:lb': 'String',
        'name:la': 'String',
        'name:sk': 'String',
        'name:uk': 'String',
        'name:hy': 'String',
        'name:sv': 'String',
        name_en: 'String',
        'name:hu': 'String',
        'name:hr': 'String',
        class: 'String',
        'name:sq': 'String',
        'name:el': 'String',
        'name:ga': 'String',
        'name:en': 'String',
        name: 'String',
        'name:gd': 'String',
        'name:ja_kana': 'String',
        'name:is': 'String',
        'name:th': 'String',
        'name:latin': 'String',
        'name:sr-Latn': 'String',
        'name:et': 'String',
        'name:es': 'String'
      },
      minzoom: 0,
      id: 'waterway',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        class: 'String',
        subclass: 'String'
      },
      minzoom: 0,
      id: 'landcover',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        class: 'String'
      },
      minzoom: 0,
      id: 'landuse',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        'name:mt': 'String',
        'name:pt': 'String',
        'name:az': 'String',
        'name:ka': 'String',
        'name:rm': 'String',
        'name:ko': 'String',
        'name:kn': 'String',
        'name:ar': 'String',
        'name:cs': 'String',
        rank: 'Number',
        name_de: 'String',
        'name:ro': 'String',
        'name:it': 'String',
        name_int: 'String',
        'name:lv': 'String',
        'name:pl': 'String',
        'name:de': 'String',
        'name:ca': 'String',
        'name:bg': 'String',
        'name:cy': 'String',
        'name:fi': 'String',
        'name:he': 'String',
        'name:da': 'String',
        ele: 'Number',
        'name:tr': 'String',
        'name:fr': 'String',
        'name:mk': 'String',
        'name:nonlatin': 'String',
        'name:fy': 'String',
        'name:be': 'String',
        'name:zh': 'String',
        'name:sl': 'String',
        'name:nl': 'String',
        'name:ja': 'String',
        'name:lt': 'String',
        'name:no': 'String',
        'name:kk': 'String',
        'name:ko_rm': 'String',
        'name:ja_rm': 'String',
        'name:br': 'String',
        'name:bs': 'String',
        'name:lb': 'String',
        'name:la': 'String',
        'name:sk': 'String',
        'name:uk': 'String',
        'name:hy': 'String',
        'name:ru': 'String',
        'name:sv': 'String',
        name_en: 'String',
        'name:hu': 'String',
        'name:hr': 'String',
        'name:sr': 'String',
        'name:sq': 'String',
        'name:el': 'String',
        'name:ga': 'String',
        'name:en': 'String',
        name: 'String',
        'name:gd': 'String',
        ele_ft: 'Number',
        'name:ja_kana': 'String',
        'name:is': 'String',
        osm_id: 'Number',
        'name:th': 'String',
        'name:latin': 'String',
        'name:sr-Latn': 'String',
        'name:et': 'String',
        'name:es': 'String'
      },
      minzoom: 0,
      id: 'mountain_peak',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        class: 'String'
      },
      minzoom: 0,
      id: 'park',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        admin_level: 'Number',
        disputed: 'Number',
        maritime: 'Number'
      },
      minzoom: 0,
      id: 'boundary',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        class: 'String'
      },
      minzoom: 0,
      id: 'aeroway',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        brunnel: 'String',
        ramp: 'Number',
        class: 'String',
        service: 'String',
        oneway: 'Number'
      },
      minzoom: 0,
      id: 'transportation',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        render_min_height: 'Number',
        render_height: 'Number'
      },
      minzoom: 0,
      id: 'building',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        'name:mt': 'String',
        'name:pt': 'String',
        'name:az': 'String',
        'name:ka': 'String',
        'name:rm': 'String',
        'name:ko': 'String',
        'name:kn': 'String',
        'name:ar': 'String',
        'name:cs': 'String',
        name_de: 'String',
        'name:ro': 'String',
        'name:it': 'String',
        name_int: 'String',
        'name:ru': 'String',
        'name:pl': 'String',
        'name:ca': 'String',
        'name:lv': 'String',
        'name:bg': 'String',
        'name:cy': 'String',
        'name:fi': 'String',
        'name:he': 'String',
        'name:da': 'String',
        'name:de': 'String',
        'name:tr': 'String',
        'name:fr': 'String',
        'name:mk': 'String',
        'name:nonlatin': 'String',
        'name:fy': 'String',
        'name:be': 'String',
        'name:zh': 'String',
        'name:sr': 'String',
        'name:sl': 'String',
        'name:nl': 'String',
        'name:ja': 'String',
        'name:lt': 'String',
        'name:no': 'String',
        'name:kk': 'String',
        'name:ko_rm': 'String',
        'name:ja_rm': 'String',
        'name:br': 'String',
        'name:bs': 'String',
        'name:lb': 'String',
        'name:la': 'String',
        'name:sk': 'String',
        'name:uk': 'String',
        'name:hy': 'String',
        'name:sv': 'String',
        name_en: 'String',
        'name:hu': 'String',
        'name:hr': 'String',
        class: 'String',
        'name:sq': 'String',
        'name:el': 'String',
        'name:ga': 'String',
        'name:en': 'String',
        name: 'String',
        'name:gd': 'String',
        'name:ja_kana': 'String',
        'name:is': 'String',
        'name:th': 'String',
        'name:latin': 'String',
        'name:sr-Latn': 'String',
        'name:et': 'String',
        'name:es': 'String'
      },
      minzoom: 0,
      id: 'water_name',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        'name:mt': 'String',
        'name:pt': 'String',
        'name:az': 'String',
        'name:ka': 'String',
        'name:rm': 'String',
        'name:ko': 'String',
        'name:kn': 'String',
        'name:ar': 'String',
        'name:cs': 'String',
        name_de: 'String',
        'name:ro': 'String',
        'name:it': 'String',
        name_int: 'String',
        'name:ru': 'String',
        'name:pl': 'String',
        'name:ca': 'String',
        'name:lv': 'String',
        'name:bg': 'String',
        'name:cy': 'String',
        'name:fi': 'String',
        'name:he': 'String',
        'name:da': 'String',
        'name:de': 'String',
        'name:tr': 'String',
        'name:fr': 'String',
        'name:mk': 'String',
        'name:nonlatin': 'String',
        'name:fy': 'String',
        'name:be': 'String',
        'name:zh': 'String',
        'name:sr': 'String',
        'name:sl': 'String',
        'name:nl': 'String',
        'name:ja': 'String',
        'name:lt': 'String',
        'name:no': 'String',
        'name:kk': 'String',
        'name:ko_rm': 'String',
        'name:ja_rm': 'String',
        'name:br': 'String',
        'name:bs': 'String',
        'name:lb': 'String',
        'name:la': 'String',
        'name:sk': 'String',
        'name:uk': 'String',
        'name:hy': 'String',
        'name:sv': 'String',
        name_en: 'String',
        'name:hu': 'String',
        'name:hr': 'String',
        class: 'String',
        'name:sq': 'String',
        network: 'String',
        'name:el': 'String',
        'name:ga': 'String',
        'name:en': 'String',
        name: 'String',
        'name:gd': 'String',
        ref: 'String',
        'name:ja_kana': 'String',
        ref_length: 'Number',
        'name:is': 'String',
        'name:th': 'String',
        'name:latin': 'String',
        'name:sr-Latn': 'String',
        'name:et': 'String',
        'name:es': 'String'
      },
      minzoom: 0,
      id: 'transportation_name',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        'name:mt': 'String',
        'name:pt': 'String',
        'name:az': 'String',
        'name:ka': 'String',
        'name:rm': 'String',
        'name:ko': 'String',
        'name:kn': 'String',
        'name:ar': 'String',
        'name:cs': 'String',
        rank: 'Number',
        name_de: 'String',
        'name:ro': 'String',
        'name:it': 'String',
        name_int: 'String',
        'name:ru': 'String',
        'name:pl': 'String',
        'name:ca': 'String',
        'name:lv': 'String',
        'name:bg': 'String',
        'name:cy': 'String',
        'name:hr': 'String',
        'name:fi': 'String',
        'name:he': 'String',
        'name:da': 'String',
        'name:de': 'String',
        'name:tr': 'String',
        'name:fr': 'String',
        'name:mk': 'String',
        'name:nonlatin': 'String',
        'name:fy': 'String',
        'name:be': 'String',
        'name:zh': 'String',
        'name:sr': 'String',
        'name:sl': 'String',
        'name:nl': 'String',
        'name:ja': 'String',
        'name:ko_rm': 'String',
        'name:no': 'String',
        'name:kk': 'String',
        capital: 'Number',
        'name:ja_rm': 'String',
        'name:br': 'String',
        'name:bs': 'String',
        'name:lb': 'String',
        'name:la': 'String',
        'name:sk': 'String',
        'name:uk': 'String',
        'name:hy': 'String',
        'name:sv': 'String',
        name_en: 'String',
        'name:hu': 'String',
        'name:lt': 'String',
        class: 'String',
        'name:sq': 'String',
        'name:el': 'String',
        'name:ga': 'String',
        'name:en': 'String',
        name: 'String',
        'name:gd': 'String',
        'name:ja_kana': 'String',
        'name:is': 'String',
        'name:th': 'String',
        'name:latin': 'String',
        'name:sr-Latn': 'String',
        'name:et': 'String',
        'name:es': 'String'
      },
      minzoom: 0,
      id: 'place',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        housenumber: 'String'
      },
      minzoom: 0,
      id: 'housenumber',
      description: ''
    }, {
      maxzoom: 14,
      fields: {
        'name:mt': 'String',
        'name:pt': 'String',
        'name:az': 'String',
        'name:ka': 'String',
        'name:rm': 'String',
        'name:ko': 'String',
        'name:kn': 'String',
        'name:ar': 'String',
        'name:cs': 'String',
        rank: 'Number',
        name_de: 'String',
        'name:ro': 'String',
        'name:it': 'String',
        name_int: 'String',
        'name:ru': 'String',
        'name:pl': 'String',
        'name:ca': 'String',
        'name:lv': 'String',
        'name:bg': 'String',
        'name:cy': 'String',
        'name:fi': 'String',
        'name:he': 'String',
        'name:da': 'String',
        subclass: 'String',
        'name:de': 'String',
        'name:tr': 'String',
        'name:fr': 'String',
        'name:mk': 'String',
        'name:nonlatin': 'String',
        'name:fy': 'String',
        'name:be': 'String',
        'name:zh': 'String',
        'name:sr': 'String',
        'name:sl': 'String',
        'name:nl': 'String',
        'name:ja': 'String',
        'name:lt': 'String',
        'name:no': 'String',
        'name:kk': 'String',
        'name:ko_rm': 'String',
        'name:ja_rm': 'String',
        'name:br': 'String',
        'name:bs': 'String',
        'name:lb': 'String',
        'name:la': 'String',
        'name:sk': 'String',
        'name:uk': 'String',
        'name:hy': 'String',
        'name:sv': 'String',
        name_en: 'String',
        'name:hu': 'String',
        'name:hr': 'String',
        class: 'String',
        'name:sq': 'String',
        'name:el': 'String',
        'name:ga': 'String',
        'name:en': 'String',
        name: 'String',
        'name:gd': 'String',
        'name:ja_kana': 'String',
        'name:is': 'String',
        'name:th': 'String',
        'name:latin': 'String',
        'name:sr-Latn': 'String',
        'name:et': 'String',
        'name:es': 'String'
      },
      minzoom: 0,
      id: 'poi',
      description: ''
    }, {
      id: 'omt_watermark',
      fields: {
        attribution: 'String'
      },
      minzoom: 0,
      maxzoom: 1
    }],
    tilejson: '2.0.0'
  };
  _exports.v3JSON = v3JSON;
  var baseStyle = {
    version: 8,
    name: 'NYCPlanning Positron',
    metadata: {
      attribution: 'Based on OpenMapTiles Positron style: https://github.com/openmaptiles/positron-gl-style'
    },
    center: [-73.869324, 40.815888],
    zoom: 9.72,
    bearing: 0,
    pitch: 0,
    sources: {
      openmaptiles: {
        type: 'vector',
        url: 'https://layers-api-staging.planninglabs.nyc/static/v3.json'
      },
      'zoning-districts': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-a.global.ssl.fastly.net/planninglabs/api/v1/map/988b5ef691ac70daef1e54c42b71beba:1543436945001/{z}/{x}/{y}.mvt']
      },
      pluto: {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-b.global.ssl.fastly.net/planninglabs/api/v1/map/7c55ac50baeaa321987b138049dabbb9:1529345122381/{z}/{x}/{y}.mvt']
      },
      zoning: {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-a.global.ssl.fastly.net/planninglabs/api/v1/map/c7f36b12d63aa9b6f288c3a9bee16950:1543436945001/{z}/{x}/{y}.mvt']
      },
      'zoning-map-amendments': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-a.global.ssl.fastly.net/planninglabs/api/v1/map/7d426546eb7eb1c5886ab833e8bf8d11:1543436972397/{z}/{x}/{y}.mvt']
      },
      'supporting-zoning': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-a.global.ssl.fastly.net/planninglabs/api/v1/map/646008c7bb1ebdc7712bb73f92eb4438:1543436972397/{z}/{x}/{y}.mvt']
      },
      'landmark-historic': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-c.global.ssl.fastly.net/planninglabs/api/v1/map/f75279b7a688a93f1d6dd2b88f2589d5:1526570453789/{z}/{x}/{y}.mvt']
      },
      floodplains: {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-d.global.ssl.fastly.net/planninglabs/api/v1/map/0f2bf7ae0bc4dddcae9cc0fe0105b879:1525336736357/{z}/{x}/{y}.mvt']
      },
      'admin-boundaries': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-a.global.ssl.fastly.net/planninglabs/api/v1/map/9a2cc95f1da96e23b6d3be3777a0384c:1525336751194/{z}/{x}/{y}.mvt']
      },
      transportation: {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-d.global.ssl.fastly.net/planninglabs/api/v1/map/cb539c7b2550978de9d0ee828d99359e:1539281332384/{z}/{x}/{y}.mvt']
      },
      'aerials-2016': {
        id: 'aerials-2016',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2016/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-2014': {
        id: 'aerials-2014',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2014/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-2012': {
        id: 'aerials-2012',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2012/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-2010': {
        id: 'aerials-2010',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2010/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-2008': {
        id: 'aerials-2008',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2008/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-2006': {
        id: 'aerials-2006',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2006/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-2004': {
        id: 'aerials-2004',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2004/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-20012': {
        id: 'aerials-20012',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/2001-2/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-1996': {
        id: 'aerials-1996',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/1996/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-1951': {
        id: 'aerials-1951',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/1951/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'aerials-1924': {
        id: 'aerials-1924',
        type: 'raster',
        tiles: ['https://maps.nyc.gov/xyz/1.0.0/photo/1924/{z}/{x}/{y}.png8'],
        tileSize: 256,
        meta: {
          description: 'NYC DoITT GIS Aerial Photography Tile Layers (TMS)',
          url: ['https://maps.nyc.gov/tiles/'],
          updated_at: 'n/a'
        }
      },
      'digital-citymap': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-b.global.ssl.fastly.net/planninglabs/api/v1/map/cfd733118e756c12ca12b2e036e785ba:1536785962086/{z}/{x}/{y}.mvt']
      },
      'boat-launches': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-c.global.ssl.fastly.net/planninglabs/api/v1/map/d1d8217f79745023833edd0d72256fd9:1539123038491/{z}/{x}/{y}.mvt']
      },
      'citibike-stations': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-a.global.ssl.fastly.net/planninglabs/api/v1/map/77b616615d3c872e1c37d69b93152a46:1544023991225/{z}/{x}/{y}.mvt']
      },
      ferries: {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-c.global.ssl.fastly.net/planninglabs/api/v1/map/e1ba4a0e5a4512214de44c6b2ee13629:1540307750946/{z}/{x}/{y}.mvt']
      },
      'preliminary-flood-insurance-rate': {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-c.global.ssl.fastly.net/planninglabs/api/v1/map/e2f160f1a9cbe6d2f4f7325fa09cdb8f:1525336736357/{z}/{x}/{y}.mvt']
      },
      paws: {
        type: 'vector',
        tiles: ['https://cartocdn-gusc-b.global.ssl.fastly.net/planninglabs/api/v1/map/601ca7ee7e097227944993cd0e219788:1542220686812/{z}/{x}/{y}.mvt']
      }
    },
    sprite: 'https://layers-api-staging.planninglabs.nyc/static/sprite',
    glyphs: 'https://tiles.planninglabs.nyc/fonts/{fontstack}/{range}.pbf',
    layers: [{
      id: 'background',
      type: 'background',
      paint: {
        'background-color': 'rgb(242,243,240)'
      }
    }, {
      id: 'park',
      type: 'fill',
      source: 'openmaptiles',
      'source-layer': 'park',
      filter: ['==', '$type', 'Polygon'],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'fill-color': 'rgb(230, 233, 229)'
      }
    }, {
      id: 'water',
      type: 'fill',
      source: 'openmaptiles',
      'source-layer': 'water',
      filter: ['==', '$type', 'Polygon'],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'fill-color': 'rgb(194, 200, 202)',
        'fill-antialias': true
      }
    }, {
      id: 'landcover_ice_shelf',
      type: 'fill',
      source: 'openmaptiles',
      'source-layer': 'landcover',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'subclass', 'ice_shelf']],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'fill-color': 'hsl(0, 0%, 98%)',
        'fill-opacity': 0.7
      }
    }, {
      id: 'landcover_glacier',
      type: 'fill',
      source: 'openmaptiles',
      'source-layer': 'landcover',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'subclass', 'glacier']],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'fill-color': 'hsl(0, 0%, 98%)',
        'fill-opacity': {
          base: 1,
          stops: [[0, 1], [8, 0.5]]
        }
      }
    }, {
      id: 'landuse_residential',
      type: 'fill',
      source: 'openmaptiles',
      'source-layer': 'landuse',
      maxzoom: 16,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'residential']],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'fill-color': 'rgb(234, 234, 230)',
        'fill-opacity': {
          base: 0.6,
          stops: [[8, 0.8], [9, 0.6]]
        }
      }
    }, {
      id: 'landcover_wood',
      type: 'fill',
      source: 'openmaptiles',
      'source-layer': 'landcover',
      minzoom: 10,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'wood']],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'fill-color': 'rgb(220,224,220)',
        'fill-opacity': {
          base: 1,
          stops: [[8, 0], [12, 1]]
        }
      }
    }, {
      id: 'waterway',
      type: 'line',
      source: 'openmaptiles',
      'source-layer': 'waterway',
      filter: ['==', '$type', 'LineString'],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'line-color': '#bbccdd',
        'line-dasharray': [3, 3]
      }
    }, {
      id: 'water_name',
      type: 'symbol',
      source: 'openmaptiles',
      'source-layer': 'water_name',
      filter: ['==', '$type', 'LineString'],
      layout: {
        'text-field': '{name:latin}\n{name:nonlatin}',
        'symbol-placement': 'line',
        'text-rotation-alignment': 'map',
        'symbol-spacing': 500,
        'text-font': ['Metropolis Medium Italic', 'Noto Sans Italic'],
        'text-size': 12
      },
      paint: {
        'text-color': 'rgb(157,169,177)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'building',
      type: 'fill',
      source: 'openmaptiles',
      'source-layer': 'building',
      minzoom: 12,
      paint: {
        'fill-color': 'rgb(234, 234, 229)',
        'fill-outline-color': 'rgb(219, 219, 218)',
        'fill-antialias': true
      }
    }, {
      id: 'tunnel_motorway_casing',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'motorway']]],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgb(213, 213, 213)',
        'line-width': {
          base: 1.4,
          stops: [[5.8, 0], [6, 3], [20, 40]]
        },
        'line-opacity': 1
      }
    }, {
      id: 'tunnel_motorway_inner',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'motorway']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgb(234,234,234)',
        'line-width': {
          base: 1.4,
          stops: [[4, 2], [6, 1.3], [20, 30]]
        }
      }
    }, {
      id: 'aeroway-taxiway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436'
      },
      source: 'openmaptiles',
      'source-layer': 'aeroway',
      minzoom: 12,
      filter: ['all', ['in', 'class', 'taxiway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'hsl(0, 0%, 88%)',
        'line-width': {
          base: 1.55,
          stops: [[13, 1.8], [20, 20]]
        },
        'line-opacity': 1
      }
    }, {
      id: 'aeroway-runway-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436'
      },
      source: 'openmaptiles',
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['all', ['in', 'class', 'runway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'hsl(0, 0%, 88%)',
        'line-width': {
          base: 1.5,
          stops: [[11, 6], [17, 55]]
        },
        'line-opacity': 1
      }
    }, {
      id: 'aeroway-area',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849345966.4436'
      },
      source: 'openmaptiles',
      'source-layer': 'aeroway',
      minzoom: 4,
      filter: ['all', ['==', '$type', 'Polygon'], ['in', 'class', 'runway', 'taxiway']],
      layout: {
        visibility: 'visible'
      },
      paint: {
        'fill-opacity': {
          base: 1,
          stops: [[13, 0], [14, 1]]
        },
        'fill-color': 'rgba(255, 255, 255, 1)'
      }
    }, {
      id: 'aeroway-runway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436'
      },
      source: 'openmaptiles',
      'source-layer': 'aeroway',
      minzoom: 11,
      maxzoom: 24,
      filter: ['all', ['in', 'class', 'runway'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          base: 1.5,
          stops: [[11, 4], [17, 50]]
        },
        'line-opacity': 1
      }
    }, {
      id: 'highway_path',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'path']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgb(234, 234, 234)',
        'line-width': {
          base: 1.2,
          stops: [[13, 1], [20, 10]]
        },
        'line-opacity': 0.9
      }
    }, {
      id: 'highway_minor',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 8,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'minor', 'service', 'track']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'hsl(0, 0%, 88%)',
        'line-width': {
          base: 1.55,
          stops: [[13, 1.8], [20, 20]]
        },
        'line-opacity': 0.9
      }
    }, {
      id: 'highway_major_casing',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk']],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgb(213, 213, 213)',
        'line-dasharray': [12, 0],
        'line-width': {
          base: 1.3,
          stops: [[10, 3], [20, 23]]
        }
      }
    }, {
      id: 'highway_major_inner',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.3,
          stops: [[10, 2], [20, 20]]
        }
      }
    }, {
      id: 'highway_major_subtle',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      maxzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'hsla(0, 0%, 85%, 0.69)',
        'line-width': 2
      }
    }, {
      id: 'highway_motorway_casing',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway']]],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgb(213, 213, 213)',
        'line-width': {
          base: 1.4,
          stops: [[5.8, 0], [6, 3], [20, 40]]
        },
        'line-dasharray': [2, 0],
        'line-opacity': 1
      }
    }, {
      id: 'highway_motorway_inner',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': {
          base: 1,
          stops: [[5.8, 'hsla(0, 0%, 85%, 0.53)'], [6, '#fff']]
        },
        'line-width': {
          base: 1.4,
          stops: [[4, 2], [6, 1.3], [20, 30]]
        }
      }
    }, {
      id: 'highway_motorway_subtle',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      maxzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'hsla(0, 0%, 85%, 0.53)',
        'line-width': {
          base: 1.4,
          stops: [[4, 2], [6, 1.3]]
        }
      }
    }, {
      id: 'railway_transit',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#dddddd',
        'line-width': 3
      }
    }, {
      id: 'railway_transit_dashline',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#fafafa',
        'line-width': 2,
        'line-dasharray': [3, 3]
      }
    }, {
      id: 'railway_service',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'rail'], ['has', 'service']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#dddddd',
        'line-width': 3
      }
    }, {
      id: 'railway_service_dashline',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'rail'], ['has', 'service']],
      layout: {
        visibility: 'visible',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#fafafa',
        'line-width': 2,
        'line-dasharray': [3, 3]
      }
    }, {
      id: 'railway',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 13,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['!has', 'service'], ['==', 'class', 'rail']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#dddddd',
        'line-width': {
          base: 1.3,
          stops: [[16, 3], [20, 7]]
        }
      }
    }, {
      id: 'railway_dashline',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 13,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['!has', 'service'], ['==', 'class', 'rail']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#fafafa',
        'line-width': {
          base: 1.3,
          stops: [[16, 2], [20, 6]]
        },
        'line-dasharray': [3, 3]
      }
    }, {
      id: 'highway_motorway_bridge_casing',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway']]],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgb(213, 213, 213)',
        'line-width': {
          base: 1.4,
          stops: [[5.8, 0], [6, 5], [20, 45]]
        },
        'line-dasharray': [2, 0],
        'line-opacity': 1
      }
    }, {
      id: 'highway_motorway_bridge_inner',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': {
          base: 1,
          stops: [[5.8, 'hsla(0, 0%, 85%, 0.53)'], [6, '#fff']]
        },
        'line-width': {
          base: 1.4,
          stops: [[4, 2], [6, 1.3], [20, 30]]
        }
      }
    }, {
      id: 'highway_name_other',
      type: 'symbol',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation_name',
      filter: ['all', ['!=', 'class', 'motorway'], ['==', '$type', 'LineString']],
      layout: {
        'text-size': 10,
        'text-max-angle': 30,
        'text-transform': 'uppercase',
        'symbol-spacing': 350,
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'symbol-placement': 'line',
        visibility: 'visible',
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name:latin} {name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': '#fff',
        'text-translate': [0, 0],
        'text-halo-width': 2,
        'text-halo-blur': 1
      }
    }, {
      id: 'highway_name_motorway',
      type: 'symbol',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5'
      },
      source: 'openmaptiles',
      'source-layer': 'transportation_name',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
      layout: {
        'text-size': 10,
        'symbol-spacing': 350,
        'text-font': ['Metropolis Light', 'Noto Sans Regular'],
        'symbol-placement': 'line',
        visibility: 'visible',
        'text-rotation-alignment': 'viewport',
        'text-pitch-alignment': 'viewport',
        'text-field': '{ref}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-translate': [0, 2],
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'boundary_state',
      type: 'line',
      metadata: {
        'mapbox:group': 'a14c9607bc7954ba1df7205bf660433f'
      },
      source: 'openmaptiles',
      'source-layer': 'boundary',
      filter: ['==', 'admin_level', 4],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': 'rgba(230, 204, 207, 0)',
        'line-width': {
          base: 1.3,
          stops: [[3, 1], [22, 15]]
        },
        'line-blur': 0.4,
        'line-dasharray': [2, 2],
        'line-opacity': 1
      }
    }, {
      id: 'boundary_country',
      type: 'line',
      metadata: {
        'mapbox:group': 'a14c9607bc7954ba1df7205bf660433f'
      },
      source: 'openmaptiles',
      'source-layer': 'boundary',
      filter: ['==', 'admin_level', 2],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': 'rgba(230, 204, 207, 0)',
        'line-width': {
          base: 1.1,
          stops: [[3, 1], [22, 20]]
        },
        'line-blur': {
          base: 1,
          stops: [[0, 0.4], [22, 4]]
        },
        'line-opacity': 1
      }
    }, {
      id: 'place_other',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 14,
      filter: ['all', ['in', 'class', 'continent', 'hamlet', 'neighbourhood', 'isolated_dwelling'], ['==', '$type', 'Point']],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_suburb',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 15,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'suburb']],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_village',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 14,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'village']],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_town',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 15,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'town']],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_city',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 14,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'capital', 2], ['==', 'class', 'city'], ['>', 'rank', 3]]],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_capital',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 12,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['==', 'capital', 2], ['==', 'class', 'city']]],
      layout: {
        'text-size': 14,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_city_large',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 12,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'capital', 2], ['<=', 'rank', 3], ['==', 'class', 'city']]],
      layout: {
        'text-size': 14,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-field': '{name:latin}\n{name:nonlatin}'
      },
      paint: {
        'text-color': 'rgb(117, 129, 145)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_state',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 12,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'state']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-transform': 'uppercase',
        'text-size': 10
      },
      paint: {
        'text-color': 'rgb(113, 129, 144)',
        'text-halo-color': 'rgb(242,243,240)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }
    }, {
      id: 'place_country_other',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'country'], ['!has', 'iso_a2']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}',
        'text-font': ['Metropolis Light Italic', 'Noto Sans Italic'],
        'text-transform': 'uppercase',
        'text-size': {
          base: 1,
          stops: [[0, 9], [6, 11]]
        }
      },
      paint: {
        'text-halo-width': 1.4,
        'text-halo-color': 'rgba(236,236,234,0.7)',
        'text-color': {
          base: 1,
          stops: [[3, 'rgb(157,169,177)'], [4, 'rgb(153, 153, 153)']]
        }
      }
    }, {
      id: 'place_country_minor',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'country'], ['>=', 'rank', 2], ['has', 'iso_a2']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-transform': 'uppercase',
        'text-size': {
          base: 1,
          stops: [[0, 10], [6, 12]]
        }
      },
      paint: {
        'text-halo-width': 1.4,
        'text-halo-color': 'rgba(236,236,234,0.7)',
        'text-color': {
          base: 1,
          stops: [[3, 'rgb(157,169,177)'], [4, 'rgb(153, 153, 153)']]
        }
      }
    }, {
      id: 'place_country_major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f'
      },
      source: 'openmaptiles',
      'source-layer': 'place',
      maxzoom: 6,
      filter: ['all', ['==', '$type', 'Point'], ['<=', 'rank', 1], ['==', 'class', 'country'], ['has', 'iso_a2']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-transform': 'uppercase',
        'text-size': {
          base: 1.4,
          stops: [[0, 10], [3, 12], [4, 14]]
        },
        'text-anchor': 'center'
      },
      paint: {
        'text-halo-width': 1.4,
        'text-halo-color': 'rgba(236,236,234,0.7)',
        'text-color': {
          base: 1,
          stops: [[3, 'rgb(157,169,177)'], [4, 'rgb(153, 153, 153)']]
        }
      }
    }]
  };
  _exports.baseStyle = baseStyle;
  var spritesJson = {
    'aqua-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 0
    },
    'aqua-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 0
    },
    'aqua-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 40
    },
    'aqua-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 40
    },
    'aqua-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 0
    },
    'aqua-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 0
    },
    'aqua-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 40
    },
    'aqua-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 40
    },
    'aqua-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 80
    },
    'aqua-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 80
    },
    'aqua-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 480,
      y: 780
    },
    'aqua-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 490,
      y: 780
    },
    'aqua-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1040,
      y: 680
    },
    'aqua-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1060,
      y: 680
    },
    'aqua-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1080,
      y: 680
    },
    'aqua-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1100,
      y: 680
    },
    'aqua_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 80
    },
    'aqua_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 80
    },
    'aqua_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 120
    },
    'aqua_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 120
    },
    'aqua_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 120
    },
    'aqua_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 120
    },
    'aqua_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 0
    },
    'aqua_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 0
    },
    'aqua_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 0
    },
    'aqua_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 0
    },
    'aqua_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 500,
      y: 780
    },
    'aqua_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 510,
      y: 780
    },
    'aqua_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1120,
      y: 680
    },
    'aqua_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1140,
      y: 680
    },
    'aqua_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1160,
      y: 680
    },
    'aqua_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1180,
      y: 680
    },
    'aqua_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 40
    },
    'aqua_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 40
    },
    'aqua_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 40
    },
    'aqua_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 40
    },
    'aqua_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 80
    },
    'aqua_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 80
    },
    'aqua_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 80
    },
    'aqua_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 80
    },
    'aqua_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 120
    },
    'aqua_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 120
    },
    'aqua_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 520,
      y: 780
    },
    'aqua_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 530,
      y: 780
    },
    'aqua_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1200,
      y: 680
    },
    'aqua_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1220,
      y: 680
    },
    'aqua_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1240,
      y: 680
    },
    'aqua_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1260,
      y: 680
    },
    'aqua_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 120
    },
    'aqua_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 120
    },
    'aqua_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 160
    },
    'aqua_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 160
    },
    'aqua_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 160
    },
    'aqua_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 160
    },
    'aqua_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 160
    },
    'aqua_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 160
    },
    'aqua_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 160
    },
    'aqua_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 160
    },
    'aqua_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 540,
      y: 780
    },
    'aqua_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 550,
      y: 780
    },
    'aqua_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 0,
      y: 720
    },
    'aqua_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 20,
      y: 720
    },
    'aqua_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 40,
      y: 720
    },
    'aqua_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 60,
      y: 720
    },
    'black-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 200
    },
    'black-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 200
    },
    'black-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 200
    },
    'black-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 200
    },
    'black-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 200
    },
    'black-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 200
    },
    'black-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 200
    },
    'black-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 200
    },
    'black-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 240
    },
    'black-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 240
    },
    'black-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 560,
      y: 780
    },
    'black-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 570,
      y: 780
    },
    'black-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 80,
      y: 720
    },
    'black-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 100,
      y: 720
    },
    'black-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 120,
      y: 720
    },
    'black-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 140,
      y: 720
    },
    'blue-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 240
    },
    'blue-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 240
    },
    'blue-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 240
    },
    'blue-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 240
    },
    'blue-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 240
    },
    'blue-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 240
    },
    'blue-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 280
    },
    'blue-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 280
    },
    'blue-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 280
    },
    'blue-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 280
    },
    'blue-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 580,
      y: 780
    },
    'blue-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 590,
      y: 780
    },
    'blue-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 160,
      y: 720
    },
    'blue-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 180,
      y: 720
    },
    'blue-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 200,
      y: 720
    },
    'blue-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 220,
      y: 720
    },
    'blue_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 280
    },
    'blue_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 280
    },
    'blue_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 280
    },
    'blue_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 280
    },
    'blue_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 0
    },
    'blue_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 0
    },
    'blue_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 0
    },
    'blue_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 0
    },
    'blue_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 0
    },
    'blue_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 0
    },
    'blue_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 600,
      y: 780
    },
    'blue_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 610,
      y: 780
    },
    'blue_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 240,
      y: 720
    },
    'blue_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 260,
      y: 720
    },
    'blue_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 280,
      y: 720
    },
    'blue_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 300,
      y: 720
    },
    'blue_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 0
    },
    'blue_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 0
    },
    'blue_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 40
    },
    'blue_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 40
    },
    'blue_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 40
    },
    'blue_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 40
    },
    'blue_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 40
    },
    'blue_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 40
    },
    'blue_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 40
    },
    'blue_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 40
    },
    'blue_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 620,
      y: 780
    },
    'blue_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 630,
      y: 780
    },
    'blue_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 320,
      y: 720
    },
    'blue_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 340,
      y: 720
    },
    'blue_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 360,
      y: 720
    },
    'blue_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 380,
      y: 720
    },
    'blue_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 80
    },
    'blue_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 80
    },
    'blue_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 80
    },
    'blue_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 80
    },
    'blue_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 80
    },
    'blue_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 80
    },
    'blue_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 80
    },
    'blue_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 80
    },
    'blue_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 120
    },
    'blue_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 120
    },
    'blue_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 640,
      y: 780
    },
    'blue_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 650,
      y: 780
    },
    'blue_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 400,
      y: 720
    },
    'blue_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 420,
      y: 720
    },
    'blue_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 440,
      y: 720
    },
    'blue_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 460,
      y: 720
    },
    'charcoal-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 120
    },
    'charcoal-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 120
    },
    'charcoal-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 120
    },
    'charcoal-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 120
    },
    'charcoal-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 120
    },
    'charcoal-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 120
    },
    'charcoal-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 160
    },
    'charcoal-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 160
    },
    'charcoal-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 160
    },
    'charcoal-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 160
    },
    'charcoal-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 660,
      y: 780
    },
    'charcoal-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 670,
      y: 780
    },
    'charcoal-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 480,
      y: 720
    },
    'charcoal-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 500,
      y: 720
    },
    'charcoal-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 520,
      y: 720
    },
    'charcoal-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 540,
      y: 720
    },
    'chartreuse-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 160
    },
    'chartreuse-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 160
    },
    'chartreuse-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 160
    },
    'chartreuse-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 160
    },
    'chartreuse-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 200
    },
    'chartreuse-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 200
    },
    'chartreuse-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 200
    },
    'chartreuse-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 200
    },
    'chartreuse-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 200
    },
    'chartreuse-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 200
    },
    'chartreuse-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 680,
      y: 780
    },
    'chartreuse-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 690,
      y: 780
    },
    'chartreuse-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 560,
      y: 720
    },
    'chartreuse-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 580,
      y: 720
    },
    'chartreuse-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 600,
      y: 720
    },
    'chartreuse-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 620,
      y: 720
    },
    'chartreuse_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 200
    },
    'chartreuse_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 200
    },
    'chartreuse_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 240
    },
    'chartreuse_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 240
    },
    'chartreuse_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 240
    },
    'chartreuse_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 240
    },
    'chartreuse_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 240
    },
    'chartreuse_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 240
    },
    'chartreuse_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 240
    },
    'chartreuse_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 240
    },
    'chartreuse_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 700,
      y: 780
    },
    'chartreuse_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 710,
      y: 780
    },
    'chartreuse_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 640,
      y: 720
    },
    'chartreuse_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 660,
      y: 720
    },
    'chartreuse_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 680,
      y: 720
    },
    'chartreuse_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 700,
      y: 720
    },
    'chartreuse_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 280
    },
    'chartreuse_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 280
    },
    'chartreuse_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 280
    },
    'chartreuse_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 280
    },
    'chartreuse_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 280
    },
    'chartreuse_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 280
    },
    'chartreuse_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 280
    },
    'chartreuse_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 280
    },
    'chartreuse_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 320
    },
    'chartreuse_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 320
    },
    'chartreuse_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 720,
      y: 780
    },
    'chartreuse_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 730,
      y: 780
    },
    'chartreuse_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 720,
      y: 720
    },
    'chartreuse_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 740,
      y: 720
    },
    'chartreuse_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 760,
      y: 720
    },
    'chartreuse_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 780,
      y: 720
    },
    'chartreuse_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 320
    },
    'chartreuse_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 320
    },
    'chartreuse_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 320
    },
    'chartreuse_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 320
    },
    'chartreuse_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 320
    },
    'chartreuse_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 320
    },
    'chartreuse_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 320
    },
    'chartreuse_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 320
    },
    'chartreuse_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 320
    },
    'chartreuse_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 320
    },
    'chartreuse_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 740,
      y: 780
    },
    'chartreuse_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 750,
      y: 780
    },
    'chartreuse_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 800,
      y: 720
    },
    'chartreuse_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 820,
      y: 720
    },
    'chartreuse_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 840,
      y: 720
    },
    'chartreuse_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 860,
      y: 720
    },
    'dark_gray-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 320
    },
    'dark_gray-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 320
    },
    'dark_gray-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 320
    },
    'dark_gray-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 320
    },
    'dark_gray-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 360
    },
    'dark_gray-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 360
    },
    'dark_gray-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 360
    },
    'dark_gray-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 360
    },
    'dark_gray-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 360
    },
    'dark_gray-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 360
    },
    'dark_gray-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 760,
      y: 780
    },
    'dark_gray-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 770,
      y: 780
    },
    'dark_gray-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 880,
      y: 720
    },
    'dark_gray-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 900,
      y: 720
    },
    'dark_gray-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 920,
      y: 720
    },
    'dark_gray-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 940,
      y: 720
    },
    'fuchsia-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 360
    },
    'fuchsia-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 360
    },
    'fuchsia-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 360
    },
    'fuchsia-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 360
    },
    'fuchsia-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 360
    },
    'fuchsia-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 360
    },
    'fuchsia-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 360
    },
    'fuchsia-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 360
    },
    'fuchsia-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 360
    },
    'fuchsia-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 360
    },
    'fuchsia-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 780,
      y: 780
    },
    'fuchsia-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 790,
      y: 780
    },
    'fuchsia-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 960,
      y: 720
    },
    'fuchsia-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 980,
      y: 720
    },
    'fuchsia-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1000,
      y: 720
    },
    'fuchsia-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1020,
      y: 720
    },
    'fuchsia_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 400
    },
    'fuchsia_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 400
    },
    'fuchsia_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 400
    },
    'fuchsia_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 400
    },
    'fuchsia_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 400
    },
    'fuchsia_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 400
    },
    'fuchsia_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 400
    },
    'fuchsia_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 400
    },
    'fuchsia_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 400
    },
    'fuchsia_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 400
    },
    'fuchsia_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 800,
      y: 780
    },
    'fuchsia_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 810,
      y: 780
    },
    'fuchsia_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1040,
      y: 720
    },
    'fuchsia_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1060,
      y: 720
    },
    'fuchsia_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1080,
      y: 720
    },
    'fuchsia_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1100,
      y: 720
    },
    'fuchsia_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 400
    },
    'fuchsia_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 400
    },
    'fuchsia_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 400
    },
    'fuchsia_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 400
    },
    'fuchsia_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 400
    },
    'fuchsia_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 400
    },
    'fuchsia_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 440
    },
    'fuchsia_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 440
    },
    'fuchsia_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 440
    },
    'fuchsia_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 440
    },
    'fuchsia_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 820,
      y: 780
    },
    'fuchsia_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 830,
      y: 780
    },
    'fuchsia_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1120,
      y: 720
    },
    'fuchsia_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1140,
      y: 720
    },
    'fuchsia_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1160,
      y: 720
    },
    'fuchsia_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1180,
      y: 720
    },
    'fuchsia_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 440
    },
    'fuchsia_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 440
    },
    'fuchsia_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 440
    },
    'fuchsia_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 440
    },
    'fuchsia_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 440
    },
    'fuchsia_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 440
    },
    'fuchsia_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 440
    },
    'fuchsia_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 440
    },
    'fuchsia_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 440
    },
    'fuchsia_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 440
    },
    'fuchsia_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 840,
      y: 780
    },
    'fuchsia_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 850,
      y: 780
    },
    'fuchsia_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1200,
      y: 720
    },
    'fuchsia_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1220,
      y: 720
    },
    'fuchsia_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1240,
      y: 720
    },
    'fuchsia_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1260,
      y: 720
    },
    'gold-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 440
    },
    'gold-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 440
    },
    'gold-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 480
    },
    'gold-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 480
    },
    'gold-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 480
    },
    'gold-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 480
    },
    'gold-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 480
    },
    'gold-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 480
    },
    'gold-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 480
    },
    'gold-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 480
    },
    'gold-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 860,
      y: 780
    },
    'gold-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 870,
      y: 780
    },
    'gold-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 0,
      y: 740
    },
    'gold-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 20,
      y: 740
    },
    'gold-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 40,
      y: 740
    },
    'gold-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 60,
      y: 740
    },
    'gold_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 480
    },
    'gold_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 480
    },
    'gold_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 480
    },
    'gold_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 480
    },
    'gold_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 480
    },
    'gold_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 480
    },
    'gold_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 480
    },
    'gold_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 480
    },
    'gold_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 520
    },
    'gold_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 520
    },
    'gold_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 880,
      y: 780
    },
    'gold_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 890,
      y: 780
    },
    'gold_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 80,
      y: 740
    },
    'gold_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 100,
      y: 740
    },
    'gold_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 120,
      y: 740
    },
    'gold_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 140,
      y: 740
    },
    'gold_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 520
    },
    'gold_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 520
    },
    'gold_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 520
    },
    'gold_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 520
    },
    'gold_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 520
    },
    'gold_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 520
    },
    'gold_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 520
    },
    'gold_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 520
    },
    'gold_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 520
    },
    'gold_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 520
    },
    'gold_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 900,
      y: 780
    },
    'gold_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 910,
      y: 780
    },
    'gold_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 160,
      y: 740
    },
    'gold_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 180,
      y: 740
    },
    'gold_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 200,
      y: 740
    },
    'gold_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 220,
      y: 740
    },
    'gold_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 520
    },
    'gold_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 520
    },
    'gold_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 520
    },
    'gold_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 520
    },
    'gold_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 560
    },
    'gold_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 560
    },
    'gold_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 560
    },
    'gold_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 560
    },
    'gold_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 560
    },
    'gold_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 560
    },
    'gold_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 920,
      y: 780
    },
    'gold_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 930,
      y: 780
    },
    'gold_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 240,
      y: 740
    },
    'gold_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 260,
      y: 740
    },
    'gold_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 280,
      y: 740
    },
    'gold_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 300,
      y: 740
    },
    'gray-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 560
    },
    'gray-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 560
    },
    'gray-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 560
    },
    'gray-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 560
    },
    'gray-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 560
    },
    'gray-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 560
    },
    'gray-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 560
    },
    'gray-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 560
    },
    'gray-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 560
    },
    'gray-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 560
    },
    'gray-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 940,
      y: 780
    },
    'gray-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 950,
      y: 780
    },
    'gray-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 320,
      y: 740
    },
    'gray-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 340,
      y: 740
    },
    'gray-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 360,
      y: 740
    },
    'gray-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 380,
      y: 740
    },
    'green-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 600
    },
    'green-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 600
    },
    'green-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 600
    },
    'green-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 600
    },
    'green-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 600
    },
    'green-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 600
    },
    'green-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 600
    },
    'green-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 600
    },
    'green-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 600
    },
    'green-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 600
    },
    'green-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 960,
      y: 780
    },
    'green-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 970,
      y: 780
    },
    'green-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 400,
      y: 740
    },
    'green-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 420,
      y: 740
    },
    'green-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 440,
      y: 740
    },
    'green-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 460,
      y: 740
    },
    'green_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 600
    },
    'green_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 600
    },
    'green_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 600
    },
    'green_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 600
    },
    'green_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 600
    },
    'green_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 600
    },
    'green_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 0
    },
    'green_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 0
    },
    'green_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 0
    },
    'green_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 0
    },
    'green_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 980,
      y: 780
    },
    'green_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 990,
      y: 780
    },
    'green_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 480,
      y: 740
    },
    'green_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 500,
      y: 740
    },
    'green_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 520,
      y: 740
    },
    'green_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 540,
      y: 740
    },
    'green_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 0
    },
    'green_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 0
    },
    'green_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 0
    },
    'green_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 0
    },
    'green_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 0
    },
    'green_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 0
    },
    'green_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 0
    },
    'green_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 0
    },
    'green_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 0
    },
    'green_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 0
    },
    'green_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1000,
      y: 780
    },
    'green_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1010,
      y: 780
    },
    'green_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 560,
      y: 740
    },
    'green_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 580,
      y: 740
    },
    'green_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 600,
      y: 740
    },
    'green_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 620,
      y: 740
    },
    'green_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 0
    },
    'green_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 0
    },
    'green_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 40
    },
    'green_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 40
    },
    'green_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 40
    },
    'green_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 40
    },
    'green_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 40
    },
    'green_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 40
    },
    'green_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 40
    },
    'green_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 40
    },
    'green_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1020,
      y: 780
    },
    'green_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1030,
      y: 780
    },
    'green_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 640,
      y: 740
    },
    'green_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 660,
      y: 740
    },
    'green_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 680,
      y: 740
    },
    'green_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 700,
      y: 740
    },
    'indigo-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 40
    },
    'indigo-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 40
    },
    'indigo-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 40
    },
    'indigo-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 40
    },
    'indigo-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 40
    },
    'indigo-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 40
    },
    'indigo-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 40
    },
    'indigo-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 40
    },
    'indigo-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 80
    },
    'indigo-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 80
    },
    'indigo-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1040,
      y: 780
    },
    'indigo-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1050,
      y: 780
    },
    'indigo-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 720,
      y: 740
    },
    'indigo-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 740,
      y: 740
    },
    'indigo-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 760,
      y: 740
    },
    'indigo-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 780,
      y: 740
    },
    'indigo_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 80
    },
    'indigo_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 80
    },
    'indigo_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 80
    },
    'indigo_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 80
    },
    'indigo_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 80
    },
    'indigo_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 80
    },
    'indigo_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 80
    },
    'indigo_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 80
    },
    'indigo_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 80
    },
    'indigo_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 80
    },
    'indigo_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1060,
      y: 780
    },
    'indigo_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1070,
      y: 780
    },
    'indigo_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 800,
      y: 740
    },
    'indigo_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 820,
      y: 740
    },
    'indigo_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 840,
      y: 740
    },
    'indigo_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 860,
      y: 740
    },
    'indigo_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 80
    },
    'indigo_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 80
    },
    'indigo_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 80
    },
    'indigo_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 80
    },
    'indigo_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 120
    },
    'indigo_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 120
    },
    'indigo_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 120
    },
    'indigo_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 120
    },
    'indigo_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 120
    },
    'indigo_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 120
    },
    'indigo_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1080,
      y: 780
    },
    'indigo_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1090,
      y: 780
    },
    'indigo_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 880,
      y: 740
    },
    'indigo_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 900,
      y: 740
    },
    'indigo_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 920,
      y: 740
    },
    'indigo_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 940,
      y: 740
    },
    'indigo_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 120
    },
    'indigo_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 120
    },
    'indigo_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 120
    },
    'indigo_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 120
    },
    'indigo_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 120
    },
    'indigo_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 120
    },
    'indigo_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 120
    },
    'indigo_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 120
    },
    'indigo_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 120
    },
    'indigo_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 120
    },
    'indigo_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1100,
      y: 780
    },
    'indigo_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1110,
      y: 780
    },
    'indigo_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 960,
      y: 740
    },
    'indigo_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 980,
      y: 740
    },
    'indigo_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1000,
      y: 740
    },
    'indigo_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1020,
      y: 740
    },
    'light_gray-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 160
    },
    'light_gray-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 160
    },
    'light_gray-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 160
    },
    'light_gray-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 160
    },
    'light_gray-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 160
    },
    'light_gray-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 160
    },
    'light_gray-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 160
    },
    'light_gray-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 160
    },
    'light_gray-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 160
    },
    'light_gray-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 160
    },
    'light_gray-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1120,
      y: 780
    },
    'light_gray-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1130,
      y: 780
    },
    'light_gray-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1040,
      y: 740
    },
    'light_gray-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1060,
      y: 740
    },
    'light_gray-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1080,
      y: 740
    },
    'light_gray-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1100,
      y: 740
    },
    'off_white-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 160
    },
    'off_white-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 160
    },
    'off_white-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 160
    },
    'off_white-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 160
    },
    'off_white-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 160
    },
    'off_white-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 160
    },
    'off_white-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 200
    },
    'off_white-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 200
    },
    'off_white-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 200
    },
    'off_white-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 200
    },
    'off_white-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1140,
      y: 780
    },
    'off_white-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1150,
      y: 780
    },
    'off_white-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1120,
      y: 740
    },
    'off_white-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1140,
      y: 740
    },
    'off_white-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1160,
      y: 740
    },
    'off_white-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1180,
      y: 740
    },
    'orange-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 200
    },
    'orange-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 200
    },
    'orange-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 200
    },
    'orange-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 200
    },
    'orange-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 200
    },
    'orange-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 200
    },
    'orange-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 200
    },
    'orange-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 200
    },
    'orange-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 200
    },
    'orange-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 200
    },
    'orange-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1160,
      y: 780
    },
    'orange-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1170,
      y: 780
    },
    'orange-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1200,
      y: 740
    },
    'orange-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1220,
      y: 740
    },
    'orange-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1240,
      y: 740
    },
    'orange-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1260,
      y: 740
    },
    'orange_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 200
    },
    'orange_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 200
    },
    'orange_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 240
    },
    'orange_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 240
    },
    'orange_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 240
    },
    'orange_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 240
    },
    'orange_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 240
    },
    'orange_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 240
    },
    'orange_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 240
    },
    'orange_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 240
    },
    'orange_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1180,
      y: 780
    },
    'orange_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1190,
      y: 780
    },
    'orange_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 0,
      y: 760
    },
    'orange_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 20,
      y: 760
    },
    'orange_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 40,
      y: 760
    },
    'orange_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 60,
      y: 760
    },
    'orange_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 240
    },
    'orange_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 240
    },
    'orange_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 240
    },
    'orange_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 240
    },
    'orange_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 240
    },
    'orange_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 240
    },
    'orange_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 240
    },
    'orange_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 240
    },
    'orange_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 280
    },
    'orange_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 280
    },
    'orange_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1200,
      y: 780
    },
    'orange_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1210,
      y: 780
    },
    'orange_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 80,
      y: 760
    },
    'orange_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 100,
      y: 760
    },
    'orange_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 120,
      y: 760
    },
    'orange_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 140,
      y: 760
    },
    'orange_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 280
    },
    'orange_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 280
    },
    'orange_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 280
    },
    'orange_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 280
    },
    'orange_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 280
    },
    'orange_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 280
    },
    'orange_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 280
    },
    'orange_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 280
    },
    'orange_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 280
    },
    'orange_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 280
    },
    'orange_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1220,
      y: 780
    },
    'orange_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1230,
      y: 780
    },
    'orange_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 160,
      y: 760
    },
    'orange_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 180,
      y: 760
    },
    'orange_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 200,
      y: 760
    },
    'orange_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 220,
      y: 760
    },
    'purple-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 280
    },
    'purple-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 280
    },
    'purple-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 280
    },
    'purple-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 280
    },
    'purple-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 320
    },
    'purple-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 320
    },
    'purple-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 320
    },
    'purple-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 320
    },
    'purple-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 320
    },
    'purple-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 320
    },
    'purple-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1240,
      y: 780
    },
    'purple-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1250,
      y: 780
    },
    'purple-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 240,
      y: 760
    },
    'purple-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 260,
      y: 760
    },
    'purple-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 280,
      y: 760
    },
    'purple-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 300,
      y: 760
    },
    'purple_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 320
    },
    'purple_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 320
    },
    'purple_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 320
    },
    'purple_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 320
    },
    'purple_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 320
    },
    'purple_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 320
    },
    'purple_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 320
    },
    'purple_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 320
    },
    'purple_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 320
    },
    'purple_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 320
    },
    'purple_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1260,
      y: 780
    },
    'purple_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 1270,
      y: 780
    },
    'purple_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 320,
      y: 760
    },
    'purple_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 340,
      y: 760
    },
    'purple_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 360,
      y: 760
    },
    'purple_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 380,
      y: 760
    },
    'purple_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 360
    },
    'purple_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 360
    },
    'purple_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 360
    },
    'purple_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 360
    },
    'purple_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 360
    },
    'purple_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 360
    },
    'purple_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 360
    },
    'purple_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 360
    },
    'purple_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 360
    },
    'purple_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 360
    },
    'purple_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 0,
      y: 800
    },
    'purple_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 10,
      y: 800
    },
    'purple_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 400,
      y: 760
    },
    'purple_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 420,
      y: 760
    },
    'purple_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 440,
      y: 760
    },
    'purple_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 460,
      y: 760
    },
    'purple_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 360
    },
    'purple_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 360
    },
    'purple_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 360
    },
    'purple_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 360
    },
    'purple_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 360
    },
    'purple_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 360
    },
    'purple_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 400
    },
    'purple_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 400
    },
    'purple_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 400
    },
    'purple_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 400
    },
    'purple_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 20,
      y: 800
    },
    'purple_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 30,
      y: 800
    },
    'purple_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 480,
      y: 760
    },
    'purple_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 500,
      y: 760
    },
    'purple_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 520,
      y: 760
    },
    'purple_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 540,
      y: 760
    },
    'red-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 400
    },
    'red-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 400
    },
    'red-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 400
    },
    'red-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 400
    },
    'red-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 400
    },
    'red-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 400
    },
    'red-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 400
    },
    'red-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 400
    },
    'red-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 400
    },
    'red-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 400
    },
    'red-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 40,
      y: 800
    },
    'red-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 50,
      y: 800
    },
    'red-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 560,
      y: 760
    },
    'red-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 580,
      y: 760
    },
    'red-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 600,
      y: 760
    },
    'red-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 620,
      y: 760
    },
    'red_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 400
    },
    'red_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 400
    },
    'red_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 440
    },
    'red_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 440
    },
    'red_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 440
    },
    'red_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 440
    },
    'red_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 440
    },
    'red_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 440
    },
    'red_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 440
    },
    'red_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 440
    },
    'red_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 60,
      y: 800
    },
    'red_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 70,
      y: 800
    },
    'red_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 640,
      y: 760
    },
    'red_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 660,
      y: 760
    },
    'red_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 680,
      y: 760
    },
    'red_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 700,
      y: 760
    },
    'red_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 440
    },
    'red_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 440
    },
    'red_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 440
    },
    'red_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 440
    },
    'red_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 440
    },
    'red_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 440
    },
    'red_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 440
    },
    'red_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 440
    },
    'red_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 480
    },
    'red_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 480
    },
    'red_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 80,
      y: 800
    },
    'red_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 90,
      y: 800
    },
    'red_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 720,
      y: 760
    },
    'red_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 740,
      y: 760
    },
    'red_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 760,
      y: 760
    },
    'red_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 780,
      y: 760
    },
    'red_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 480
    },
    'red_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 480
    },
    'red_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 480
    },
    'red_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 480
    },
    'red_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 480
    },
    'red_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 480
    },
    'red_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 480
    },
    'red_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 480
    },
    'red_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 480
    },
    'red_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 480
    },
    'red_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 100,
      y: 800
    },
    'red_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 110,
      y: 800
    },
    'red_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 800,
      y: 760
    },
    'red_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 820,
      y: 760
    },
    'red_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 840,
      y: 760
    },
    'red_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 860,
      y: 760
    },
    'silver-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 480
    },
    'silver-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 480
    },
    'silver-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 480
    },
    'silver-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 480
    },
    'silver-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 520
    },
    'silver-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 520
    },
    'silver-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 520
    },
    'silver-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 520
    },
    'silver-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 520
    },
    'silver-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 520
    },
    'silver-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 120,
      y: 800
    },
    'silver-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 130,
      y: 800
    },
    'silver-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 880,
      y: 760
    },
    'silver-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 900,
      y: 760
    },
    'silver-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 920,
      y: 760
    },
    'silver-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 940,
      y: 760
    },
    'teal-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 520
    },
    'teal-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 520
    },
    'teal-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 520
    },
    'teal-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 520
    },
    'teal-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 520
    },
    'teal-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 520
    },
    'teal-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 520
    },
    'teal-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 520
    },
    'teal-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 520
    },
    'teal-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 520
    },
    'teal-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 140,
      y: 800
    },
    'teal-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 150,
      y: 800
    },
    'teal-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 960,
      y: 760
    },
    'teal-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 980,
      y: 760
    },
    'teal-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1000,
      y: 760
    },
    'teal-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1020,
      y: 760
    },
    'teal_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 560
    },
    'teal_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 560
    },
    'teal_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 560
    },
    'teal_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 560
    },
    'teal_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 560
    },
    'teal_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 560
    },
    'teal_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 560
    },
    'teal_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 560
    },
    'teal_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 560
    },
    'teal_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 560
    },
    'teal_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 160,
      y: 800
    },
    'teal_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 170,
      y: 800
    },
    'teal_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1040,
      y: 760
    },
    'teal_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1060,
      y: 760
    },
    'teal_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1080,
      y: 760
    },
    'teal_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1100,
      y: 760
    },
    'teal_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 560
    },
    'teal_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 560
    },
    'teal_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 560
    },
    'teal_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 560
    },
    'teal_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 560
    },
    'teal_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 560
    },
    'teal_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 600
    },
    'teal_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 600
    },
    'teal_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 600
    },
    'teal_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 600
    },
    'teal_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 180,
      y: 800
    },
    'teal_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 190,
      y: 800
    },
    'teal_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1120,
      y: 760
    },
    'teal_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1140,
      y: 760
    },
    'teal_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1160,
      y: 760
    },
    'teal_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1180,
      y: 760
    },
    'teal_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 600
    },
    'teal_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 600
    },
    'teal_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 600
    },
    'teal_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 600
    },
    'teal_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 600
    },
    'teal_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 600
    },
    'teal_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 600
    },
    'teal_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 600
    },
    'teal_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 600
    },
    'teal_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 600
    },
    'teal_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 200,
      y: 800
    },
    'teal_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 210,
      y: 800
    },
    'teal_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1200,
      y: 760
    },
    'teal_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1220,
      y: 760
    },
    'teal_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1240,
      y: 760
    },
    'teal_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 1260,
      y: 760
    },
    'white-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 600
    },
    'white-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 600
    },
    'white-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 640
    },
    'white-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 640
    },
    'white-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 640
    },
    'white-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 640
    },
    'white-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 640
    },
    'white-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 640
    },
    'white-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 640
    },
    'white-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 640
    },
    'white-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 220,
      y: 800
    },
    'white-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 230,
      y: 800
    },
    'white-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 0,
      y: 780
    },
    'white-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 20,
      y: 780
    },
    'white-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 40,
      y: 780
    },
    'white-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 60,
      y: 780
    },
    'white_smoke-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 640
    },
    'white_smoke-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 640
    },
    'white_smoke-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 640
    },
    'white_smoke-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 640
    },
    'white_smoke-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 640
    },
    'white_smoke-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 640
    },
    'white_smoke-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 640
    },
    'white_smoke-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 640
    },
    'white_smoke-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 640
    },
    'white_smoke-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 640
    },
    'white_smoke-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 240,
      y: 800
    },
    'white_smoke-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 250,
      y: 800
    },
    'white_smoke-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 80,
      y: 780
    },
    'white_smoke-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 100,
      y: 780
    },
    'white_smoke-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 120,
      y: 780
    },
    'white_smoke-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 140,
      y: 780
    },
    'yellow-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 640
    },
    'yellow-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 640
    },
    'yellow-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 640
    },
    'yellow-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 640
    },
    'yellow-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 640
    },
    'yellow-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 640
    },
    'yellow-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 640
    },
    'yellow-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 640
    },
    'yellow-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1040,
      y: 640
    },
    'yellow-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1080,
      y: 640
    },
    'yellow-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 260,
      y: 800
    },
    'yellow-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 270,
      y: 800
    },
    'yellow-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 160,
      y: 780
    },
    'yellow-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 180,
      y: 780
    },
    'yellow-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 200,
      y: 780
    },
    'yellow-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 220,
      y: 780
    },
    'yellow_dark-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1120,
      y: 640
    },
    'yellow_dark-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1160,
      y: 640
    },
    'yellow_dark-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1200,
      y: 640
    },
    'yellow_dark-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1240,
      y: 640
    },
    'yellow_dark-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 0,
      y: 680
    },
    'yellow_dark-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 40,
      y: 680
    },
    'yellow_dark-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 80,
      y: 680
    },
    'yellow_dark-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 120,
      y: 680
    },
    'yellow_dark-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 160,
      y: 680
    },
    'yellow_dark-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 200,
      y: 680
    },
    'yellow_dark-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 280,
      y: 800
    },
    'yellow_dark-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 290,
      y: 800
    },
    'yellow_dark-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 240,
      y: 780
    },
    'yellow_dark-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 260,
      y: 780
    },
    'yellow_dark-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 280,
      y: 780
    },
    'yellow_dark-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 300,
      y: 780
    },
    'yellow_desaturated-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 240,
      y: 680
    },
    'yellow_desaturated-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 280,
      y: 680
    },
    'yellow_desaturated-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 320,
      y: 680
    },
    'yellow_desaturated-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 360,
      y: 680
    },
    'yellow_desaturated-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 400,
      y: 680
    },
    'yellow_desaturated-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 440,
      y: 680
    },
    'yellow_desaturated-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 480,
      y: 680
    },
    'yellow_desaturated-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 520,
      y: 680
    },
    'yellow_desaturated-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 560,
      y: 680
    },
    'yellow_desaturated-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 600,
      y: 680
    },
    'yellow_desaturated-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 300,
      y: 800
    },
    'yellow_desaturated-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 310,
      y: 800
    },
    'yellow_desaturated-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 320,
      y: 780
    },
    'yellow_desaturated-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 340,
      y: 780
    },
    'yellow_desaturated-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 360,
      y: 780
    },
    'yellow_desaturated-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 380,
      y: 780
    },
    'yellow_light-45': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 640,
      y: 680
    },
    'yellow_light-45-135': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 680,
      y: 680
    },
    'yellow_light-45-135-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 720,
      y: 680
    },
    'yellow_light-45-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 760,
      y: 680
    },
    'yellow_light-45_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 800,
      y: 680
    },
    'yellow_light-45_altbold-135_altbold': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 840,
      y: 680
    },
    'yellow_light-45_altbold-135_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 880,
      y: 680
    },
    'yellow_light-45_altbold-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 920,
      y: 680
    },
    'yellow_light-45_dash': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 960,
      y: 680
    },
    'yellow_light-45_dash-dot': {
      height: 40,
      pixelRatio: 2,
      width: 40,
      x: 1000,
      y: 680
    },
    'yellow_light-diagonal-1px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 320,
      y: 800
    },
    'yellow_light-diagonal-2px': {
      height: 10,
      pixelRatio: 2,
      width: 10,
      x: 330,
      y: 800
    },
    'yellow_light-diagonal-3px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 400,
      y: 780
    },
    'yellow_light-diagonal-4px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 420,
      y: 780
    },
    'yellow_light-diagonal-5px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 440,
      y: 780
    },
    'yellow_light-diagonal-6px': {
      height: 20,
      pixelRatio: 2,
      width: 20,
      x: 460,
      y: 780
    }
  };
  _exports.spritesJson = spritesJson;
  var _default = {};
  _exports.default = _default;
});