define("labs-applicant-maps/mirage/models/project", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    areaMaps: (0, _emberCliMirage.hasMany)('area-map'),
    taxMaps: (0, _emberCliMirage.hasMany)('tax-map'),
    zoningChangeMaps: (0, _emberCliMirage.hasMany)('zoning-change-map'),
    zoningSectionMaps: (0, _emberCliMirage.hasMany)('zoning-section-map'),
    geometricProperties: (0, _emberCliMirage.hasMany)('geometric-property')
  });

  _exports.default = _default;
});